import React from 'react';
import { Container, Grid, Typography, Link, Avatar, IconButton } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import YouTubeIcon from '@mui/icons-material/YouTube';

const PREFIX = 'Footer';
const classes = {
  root: `${PREFIX}-root`,
  section: `${PREFIX}-section`,
  link: `${PREFIX}-link`,
  logo: `${PREFIX}-logo`,
  socialIcons: `${PREFIX}-socialIcons`,
  iconButton: `${PREFIX}-iconButton`,
  instagram: `${PREFIX}-instagram`,
  facebook: `${PREFIX}-facebook`,
  youtube: `${PREFIX}-youtube`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    backgroundColor: '#2b6d30',
    color: theme.palette.common.white,
    padding: theme.spacing(4),
    marginTop: theme.spacing(4),
  },
  [`& .${classes.section}`]: {
    marginBottom: theme.spacing(2),
  },
  [`& .${classes.link}`]: {
    color: theme.palette.common.white,
    textDecoration: 'none',
    marginBottom: theme.spacing(1),
    display: 'block',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  [`& .${classes.logo}`]: {
    width: theme.spacing(19),
    height: theme.spacing(19),
    marginRight: theme.spacing(2),
  },
  [`& .${classes.socialIcons}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    '& a': {
      color: theme.palette.common.white,
      marginRight: theme.spacing(1),
      '&:hover': {
        color: theme.palette.secondary.main,
      },
    },
  },
  [`& .${classes.iconButton}`]: {
    color: theme.palette.common.white,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  [`& .${classes.instagram}:hover`]: {
    color: '#E1306C',
  },
  [`& .${classes.facebook}:hover`]: {
    color: '#3b5998',
  },
  [`& .${classes.youtube}:hover`]: {
    color: 'red',
  },
}));

const Footer = () => {
  return (
    <Root className={classes.root}>
      <Container align='center'>
        <Grid container spacing={3} alignItems="center">
          <Grid item xs={12} sm={3}>
            <Avatar
              src="images\whitecolourLogo.png"
              alt="Company Logo"
              className={classes.logo}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className={classes.section}>
              <Typography variant="h6">Address</Typography>
              <Typography variant="body2">
                SRI SUDHEENDRA OILS<br />
                Katamraj Camp, Kallajjanal village<br />
                Mydolalu Post, Bhadravathi taluk<br />
                Shivamogga, Karnataka 577243<br />
                India
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className={classes.section}>
              <Typography variant="h6">Contact Us</Typography>
              <Typography variant="body2">
                Phone:<br />
                +91 96862 58808<br />
                +91 99002 70037<br />
                Email:<br />
                sajan.coldpress@gmail.com
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={3}>
            <div className={classes.section}>
              <Typography variant="h6">Links</Typography>
              <Link component={RouterLink} to="/" className={classes.link}>Home</Link>
              <Link component={RouterLink} to="/aboutus" className={classes.link}>About Us</Link>
              <Link component={RouterLink} to="/contactus" className={classes.link}>Contact Us</Link>
            </div>
          </Grid>
        </Grid>
        <div className={classes.socialIcons}>
          <Link href="https://www.instagram.com" target="_blank">
            <IconButton edge="start" className={`${classes.iconButton} ${classes.instagram}`} aria-label="instagram">
              <InstagramIcon />
            </IconButton>
          </Link>
          <Link href="https://www.facebook.com" target="_blank">
            <IconButton edge="start" className={`${classes.iconButton} ${classes.facebook}`} aria-label="facebook">
              <FacebookIcon />
            </IconButton>
          </Link>
          <Link href="https://www.youtube.com" target="_blank">
            <IconButton edge="start" className={`${classes.iconButton} ${classes.youtube}`} aria-label="youtube">
              <YouTubeIcon />
            </IconButton>
          </Link>
        </div>
      </Container>
    </Root>
  );
};

export default Footer;
