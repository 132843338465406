import React from 'react';
import { Container, Grid, Card, CardActionArea, CardMedia, CardContent, Typography, Button, Link } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'ProductsOverview';
const classes = {
  root: `${PREFIX}-root`,
  card: `${PREFIX}-card`,
  media: `${PREFIX}-media`,
  content: `${PREFIX}-content`,
  button: `${PREFIX}-button`,
  buttonContainer: `${PREFIX}-buttonContainer`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    marginTop: theme.spacing(4),
  },
  [`& .${classes.card}`]: {
    maxWidth: 300,
    width: '100%',
    margin: 'auto',
    marginBottom: theme.spacing(4),
    borderRadius: theme.spacing(2),
    boxShadow: '0px 8px 10px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'scale(1.05)',
    },
  },
  [`& .${classes.media}`]: {
    height: 370,
    borderRadius: theme.spacing(2, 2, 0, 0), 
    objectFit: 'cover', 
  },
  [`& .${classes.content}`]: {
    textAlign: 'center',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  [`& .${classes.button}`]: {
    marginTop: theme.spacing(1),
  },
  [`& .${classes.buttonContainer}`]: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
  },
}));

const products = [
  {
    name: 'GROUNDNUT OIL',
    image: '/images/secondproduct.png',
    description: 'Rich in Nutrients and Antioxidants. Lowers lipid levels. Controls Diabetes. Improves Skin and Hair health. Anti-ageing Properties.',
    price: '$30',
    amazonLink: 'https://www.amazon.com/',
    flipkartLink: 'https://www.flipkart.com/',
  },
  {
    name: 'SESAME OIL',
    image: '/images/productfirst.jpg',
    description: 'Rich in proteins, Vitamins, and Antioxidants. Good for Heart. Lowers Blood Pressure. Improves Bone Health. Regulates Blood Sugar.',
    price: '$35',
    amazonLink: 'https://www.amazon.com/',
    flipkartLink: 'https://www.flipkart.com/',
  },
  {
    name: 'COCONUT OIL',
    image: '/images/coconutproduct.jpg',
    description: 'Contains Vitamin C and Vitamin E. Moisturizes the Hair and Scalp. Good for skin health. Good for Heart. Helps in Weight Loss.',
    price: '$35',
    amazonLink: 'https://www.amazon.com/',
    flipkartLink: 'https://www.flipkart.com/',
  },
  {
    name: 'ALMOND OIL',
    image: '/images/almondoil.jpg',
    description: 'Rich in Antioxidants. Improves skin health. Good for hair growth. Lowers Cholesterol. Controls Blood Sugar. Helps in Weight Loss.',
    price: '$35',
    amazonLink: 'https://www.amazon.com/',
    flipkartLink: 'https://www.flipkart.com/',
  },
  {
    name: 'KALONJI OIL',
    image: '/images/kalongioil.jpg',
    description: 'Boosts Memory. Controls Diabetes. Makes Teeth Stronger. Relieves Asthma. Protects Kidney.',
    price: '$35',
    amazonLink: 'https://www.amazon.com/',
    flipkartLink: 'https://www.flipkart.com/',
  },
 
  {
    name: 'MUSTARD OIL',
    image: '/images/mustardoil.jpg',
    description: 'Rich in Omega-3 and Omega-6 fatty acids. Promotes heart health. Anti-inflammatory properties. ',
    price: '$35',
    amazonLink: 'https://www.amazon.com/',
    flipkartLink: 'https://www.flipkart.com/',
  },
  {
    name: 'YELLOW MUSTARD OIL',
    image: 'https://th.bing.com/th/id/OIP.8SSgK6--k34X_5XQtrGPAwHaJQ?rs=1&pid=ImgDetMain',
    description: 'Rich in Omega-3 and Omega-6 fatty acids. Promotes heart health. Anti-inflammatory properties. ',
    price: '$35',
    amazonLink: 'https://www.amazon.com/',
    flipkartLink: 'https://www.flipkart.com/',
  },
  {
    name: 'NIGER SEED OIL',
    image: '/images/nigeroilimage.jpg',
    description: 'Rich in Omega-3 and Omega-6 fatty acids. Promotes heart health. Anti-inflammatory properties. ',
    price: '$35',
    amazonLink: 'https://www.amazon.com/',
    flipkartLink: 'https://www.flipkart.com/',
  },
  
  {
    name: 'SAFFLOWER OIL',
    image: '/images/saffloweroil.jpg',
    description: ' A versatile, heart-healthy oil rich in unsaturated fats and linoleic acid. Ideal for high-heat cooking and skincare.',
    price: '$35',
    amazonLink: 'https://www.amazon.com/',
    flipkartLink: 'https://www.flipkart.com/',
  },

  {
    name: 'SUNFLOWER OIL',
    image: '/images/sunflowerimage.jpg',
    description: 'Rich in Omega-3 and Omega-6 fatty acids. Promotes heart health. Anti-inflammatory properties. ',
    price: '$35',
    amazonLink: 'https://www.amazon.com/',
    flipkartLink: 'https://www.flipkart.com/',
  },
];

const ProductsOverview = () => {
  return (
    <Root className={classes.root}>
      <Container align="center">
        <Typography
          variant="h3"
          align="center"
          gutterBottom
          sx={{ fontSize: { xs: '24px', sm: '30px', md: '36px' }, fontWeight: 'bold', color: 'primary.main' }}
        >
          Discover Our Selection of Pure Oils
        </Typography>
        <Grid container spacing={4}>
          {products.map((product, index) => (
            <Grid item xs={12} sm={6} md={4} key={index}>
              <Card className={classes.card}>
                <CardActionArea>
                  <CardMedia
                    className={classes.media}
                    image={product.image}
                    title={product.name}
                  />
                  <CardContent className={classes.content}>
                    <Typography variant="h6" gutterBottom fontWeight="bold">
                      {product.name}
                    </Typography>
                    <Typography variant="body2" color="textSecondary" fontSize="14px">
                      {product.description}
                    </Typography>
                  </CardContent>
                  <div className={classes.buttonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      className={classes.button}
                      component={Link}
                      href={product.amazonLink}
                      target="_blank"
                    >
                      Buy Now
                    </Button>
                  </div>
                </CardActionArea>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Root>
  );
};

export default ProductsOverview;
