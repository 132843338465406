import React from 'react';
import { AppBar, Toolbar, IconButton, Typography, Drawer, List, ListItem, ListItemText } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { styled, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Link } from 'react-router-dom';

const PREFIX = 'Navbar';
const classes = {
  root: `${PREFIX}-root`,
  menuButton: `${PREFIX}-menuButton`,
  title: `${PREFIX}-title`,
  navLinks: `${PREFIX}-navLinks`,
  drawerList: `${PREFIX}-drawerList`,
  link: `${PREFIX}-link`
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    flexGrow: 1,
  },
  [`& .${classes.menuButton}`]: {
    marginRight: theme.spacing(2),
  },
  [`& .${classes.title}`]: {
    flexGrow: 1,
  },
  [`& .${classes.navLinks}`]: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginLeft: 'auto',
  },
  [`& .${classes.drawerList}`]: {
    width: 250,
  },
  [`& .${classes.link}`]: {
    textDecoration: 'none',
    color: '#40C725',
    fontSize: '1.1rem',
    fontWeight: 'bold',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    transition: 'color 0.3s',
    '&:hover': {
      color: '#247713',
    },
  },
}));

const Navbar = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setDrawerOpen(!drawerOpen);
  };

  const drawer = (
    <div className={classes.drawerList} role="presentation" onClick={handleDrawerToggle} onKeyDown={handleDrawerToggle}>
      <List>
        {[{ name: 'Home', url: "/" }, { name: 'About Us', url: "/aboutus" },  { name: 'Contact Us', url: "/contactus" }].map((text) => (
          <ListItem button key={text.name} component={Link} to={text.url}>
            <ListItemText primary={text.name} />
          </ListItem>
        ))}
      </List>
    </div>
  );

  return (
    <Root className={classes.root}>
      <AppBar position="static" sx={{ backgroundColor: 'white', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', marginBottom: '10px' }}>
        <Toolbar>
          <Link to="/" style={{ display: 'flex', alignItems: 'center' }}>
            <img src="images/navbarlogo.png" alt="Company Logo" height="60" style={{ borderRadius: '100%', marginRight: '15px' }} />
          </Link>
          <Typography variant="h6" className={classes.title}>
            Company Name
          </Typography>
          {isMobile ? (
            <>
              <IconButton edge="start" className={classes.menuButton} sx={{ backgroundColor: '#40c725', color: 'white' }} aria-label="menu" onClick={handleDrawerToggle}>
                <MenuIcon />
              </IconButton>
              <Drawer anchor="right" open={drawerOpen} onClose={handleDrawerToggle}>
                {drawer}
              </Drawer>
            </>
          ) : (
            <div className={classes.navLinks}>
              <Link to="/" className={classes.link}>Home</Link>
              <Link to="/aboutus" className={classes.link}>About Us</Link>
              <Link to="/contactus" className={classes.link}>Contact Us</Link>
            </div>
          )}
        </Toolbar>
      </AppBar>
    </Root>
  );
};

export default Navbar;
