import React, { useEffect, useRef } from 'react';
import { Container, Typography, Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import CompanyJourneyShowcase from './CompanyJourneyShowcase';
import VideoPage from './VideoPage';
import { useInView } from 'react-intersection-observer';

const PREFIX = 'AboutUs';
const classes = {
  root: `${PREFIX}-root`,
  section: `${PREFIX}-section`,
  box: `${PREFIX}-box`,
  title: `${PREFIX}-title`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2),
  },
  [`& .${classes.section}`]: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    transition: 'transform 0.5s ease-out',
    transform: 'translateY(20px)',
    '&.visible': {
      transform: 'translateY(0)',
    },
  },
  [`& .${classes.box}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(4),
    padding: theme.spacing(4),
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(3),
    transition: 'transform 0.5s ease-out',
    transform: 'translateY(20px)',
    '&.visible': {
      transform: 'translateY(0)',
    },
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
      alignItems: 'center',
    },
  },
  [`& .${classes.title}`]: {
    marginBottom: theme.spacing(2),
    color: theme.palette.primary.main,
    textTransform: 'uppercase',
    fontFamily: 'Roboto, sans-serif', // Example font family
    fontWeight: 700, // Example font weight
  },
}));

const AboutUs = () => {
  const { ref, inView } = useInView({
    triggerOnce: false,
    threshold: 0.9, // Adjust threshold as needed
  });

  return (
    <>
      <Root className={classes.root}>
        <Container>
          <Box className={`${classes.box} ${inView ? 'visible' : ''}`} ref={ref}>
            <Box sx={{ flex: '1 1 50%' }}>
              <Typography variant="h3" className={classes.title} gutterBottom sx={{ fontFamily: "Roboto, Arial, sans-serif" }}>
                About Us
              </Typography>
              <Typography variant="body1" sx={{ fontFamily: "Arial, sans-serif" }}>
                Welcome to SAJAN Cold Pressed Oils! At SAJAN, we are dedicated to providing natural, high-quality food-grade oils. Our oils are produced by M/s. Sri Sudheendra Oils, ensuring sustainable and chemical-free products. We source the best raw materials directly from farmers, carefully drying and sorting the seeds. Our cold pressed oils are extracted organically to preserve their original flavor, aroma, and nutrients, including heart-healthy fats, vitamins, and antioxidants. Choose SAJAN Cold Pressed Oils for a healthier, more natural cooking experience. Thank you for trusting us to bring the best of nature to your table.
              </Typography>
            </Box>
            <Box sx={{ flex: '1 1 50%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <VideoPage videourl="/images/sajanoiview.mp4" fallbackImageUrl="/images/homeimage.png" />
            </Box>
          </Box>

          <Box className={`${classes.section} ${inView ? 'visible' : ''}`} ref={ref}>
            <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Montserrat, sans-serif', color: '#1976d2', fontWeight: 600 }}>
              Company Details
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', color: '#333', lineHeight: 1.8 }}>
              <strong>SAJAN Cold Pressed Oils</strong><br />
              <strong>Parent Company:</strong> M/s. Sri Sudheendra Oils<br />
              <strong>Location:</strong> Katamraj Camp, Kallajjanal Village, Mydolalu Post, Bhadravathi Taluk, Shivamogga, Karnataka 577243, India<br />
              <strong>License Number:</strong> 21223143000105<br /><br />
              SAJAN Cold Pressed Oils, produced by M/s. Sri Sudheendra Oils, offers natural, sustainable, and chemical-free food-grade oils. We source premium raw materials directly from farmers and use a cold pressing technique to retain original flavor, aroma, and nutrients.
            </Typography>
          </Box>

          <Box className={`${classes.section} ${inView ? 'visible' : ''}`} ref={ref}>
            <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Montserrat, sans-serif', color: '#1976d2', fontWeight: 600 }}>
              Our Mission
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', color: '#333', lineHeight: 1.8 }}>
              To provide pure, natural oils while supporting sustainable farming and promoting health and well-being.
            </Typography>
          </Box>

          <Box className={`${classes.section} ${inView ? 'visible' : ''}`} ref={ref}>
            <Typography variant="h4" gutterBottom sx={{ fontFamily: 'Roboto, sans-serif', color: '#1565c0', fontWeight: 600, marginBottom: '1rem' }}>
              Mission and Core Values
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'Arial, sans-serif', color: '#444', lineHeight: 1.8 }}>
              At SAJAN Cold Pressed Oils, our mission is to deliver pure, natural oils while supporting sustainable farming and promoting health and well-being. We are committed to:
              <ul style={{ paddingLeft: '1.5rem', marginTop: '1rem' }}>
                <li><strong>Purity:</strong> Providing chemical-free oils sourced directly from farmers.</li>
                <li><strong>Sustainability:</strong> Supporting eco-friendly practices and fair trade.</li>
                <li><strong>Quality:</strong> Ensuring our oils retain their original nutrients and flavors.</li>
                <li><strong>Integrity:</strong> Operating with honesty and transparency.</li>
                <li><strong>Community:</strong> Contributing positively to local communities.</li>
                <li><strong>Customer Satisfaction:</strong> Providing exceptional service and products.</li>
              </ul>
            </Typography>
          </Box>

        </Container>
      </Root>
      <CompanyJourneyShowcase />
    </>
  );
};

export default AboutUs;
