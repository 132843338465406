import React from 'react';
import { Container, Typography, Grid, Card, CardMedia } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'CompanyJourneyShowcase';
const classes = {
  root: `${PREFIX}-root`,
  section: `${PREFIX}-section`,
  mediaCard: `${PREFIX}-mediaCard`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    color: theme.palette.text.primary,
  },
  [`& .${classes.section}`]: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
  },
  [`& .${classes.mediaCard}`]: {
    height: 300,
    [theme.breakpoints.down('sm')]: {
      height: 200,
    },
  },
}));

const mediaItems = [
  {
    type: 'image',
    src: '/images/journeyimage1.jpeg',
    alt: 'Company Journey 1',
  },
  {
    type: 'image',
    src: '/images/journeyimage2.jpeg',
    alt: 'Company Journey 2',
  },
  {
    type: 'image',
    src: '/images/journeyimage3.jpeg',
    alt: 'Company Journey 3',
  },
  {
    type: 'image',
    src: '/images/journeyimage4.jpeg',
    alt: 'Company Journey 4',
  },
];

const CompanyJourneyShowcase = () => {
  return (
    <Root className={classes.root}>
      <Container>
        <div className={classes.section}>
          <Typography variant="h4" align="center" gutterBottom sx={{ fontFamily: 'Montserrat, sans-serif', color: '#1a237e' }}>
            Our Journey Through the Years
          </Typography>
          <Typography variant="subtitle1" align="center" gutterBottom sx={{ fontFamily: 'Arial, sans-serif', color: '#333' }}>
            High-quality images showcasing the company’s journey and achievements over the years.
          </Typography>
          <Grid container spacing={3}>
            {mediaItems.map((item, index) => (
              <Grid item xs={12} sm={6} md={3} key={index}>
                <Card>
                  <CardMedia
                    component="img"
                    alt={item.alt}
                    image={item.src}
                    className={classes.mediaCard}
                  />
                </Card>
              </Grid>
            ))}
          </Grid>
        </div>
      </Container>
    </Root>
  );
};

export default CompanyJourneyShowcase;
