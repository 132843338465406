import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import { Container, Grid, TextField, Button, Typography, Link, Paper } from '@mui/material';
import { styled } from '@mui/material/styles';
import Swal from 'sweetalert2';

const PREFIX = 'ContactPage';
const classes = {
  root: `${PREFIX}-root`,
  form: `${PREFIX}-form`,
  paper: `${PREFIX}-paper`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    padding: theme.spacing(4),
  },
  [`& .${classes.form}`]: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  },
  [`& .${classes.paper}`]: {
    padding: theme.spacing(2),
    textAlign: 'center',
  },
}));

const ContactPage = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: '' });
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = /^[a-zA-Z\s]+$/.test(formData.name) ? '' : 'Name should contain only letters.';
    tempErrors.email = formData.email && /^.+@.+\..+$/.test(formData.email)
      ? ''
      : 'Email is not valid.';
    tempErrors.phone = /^[6-9]\d{9}$/.test(formData.phone) ? '' : 'Phone number is not valid.';
    tempErrors.message = formData.message ? '' : 'This field is required.';
    setErrors(tempErrors);
    return Object.values(tempErrors).every((x) => x === '');
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!formData.email) {
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fill the email box',
      });
      return;
    }

    if (validate()) {
      setLoading(true);

      const templateParams = {
        to_name: 'Sajan Oils',
        from_name: formData.name,
        from_email: formData.email,
        from_phone: formData.phone,
        message: formData.message,
      };

      emailjs
        .send(
          process.env.REACT_APP_EMAILJS_SERVICE_ID,
          process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
          templateParams,
          process.env.REACT_APP_EMAILJS_USER_ID
        )
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          setLoading(false);
          Swal.fire({
            icon: 'success',
            title: 'Message Sent Successfully!',
            text: 'We will get back to you soon.',
          });
          setFormData({
            name: '',
            email: '',
            phone: '',
            message: '',
          });
        })
        .catch((err) => {
          console.error('FAILED...', err);
          setLoading(false);
          Swal.fire({
            icon: 'error',
            title: 'Failed to Send Message',
            text: 'Please try again later.',
          });
        });
    } else {
      console.error('Form validation failed.');
      Swal.fire({
        icon: 'error',
        title: 'Validation Error',
        text: 'Please fill out all required fields correctly.',
      });
    }
  };

  return (
    <Root className={classes.root}>
      <Container>
        <Typography variant="h3" align="center" gutterBottom>
          Contact Us
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <form className={classes.form} onSubmit={handleSubmit}>
                <TextField
                  label="Name"
                  variant="outlined"
                  fullWidth
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  error={!!errors.name}
                  helperText={errors.name}
                />
                <TextField
                  label="Email"
                  variant="outlined"
                  fullWidth
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  error={!!errors.email}
                  helperText={errors.email}
                />
                <TextField
                  label="Phone Number"
                  variant="outlined"
                  fullWidth
                  name="phone"
                  value={formData.phone}
                  onChange={handleChange}
                  error={!!errors.phone}
                  helperText={errors.phone}
                />
                <TextField
                  label="Message"
                  variant="outlined"
                  multiline
                  rows={4}
                  fullWidth
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  error={!!errors.message}
                  helperText={errors.message}
                />
                <Button variant="contained" color="primary" type="submit" disabled={loading}>
                  {loading ? 'Sending...' : 'Send Message'}
                </Button>
              </form>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper className={classes.paper}>
              <Typography variant="h5" gutterBottom>
                Our Contact Information
              </Typography>
              <Typography variant="body1">
                SRI SUDHEENDRA OILS<br />
                Katamraj Camp, Kallajjanal Village, Mydolalu Post,<br />
                Bhadravathi Taluk, Shivamogga,<br />
                Karnataka 577243, India<br />
              </Typography>
              <Typography variant="body1">
                Phone: :+91 96862 58808, +91 99002 70037
              </Typography>
              <Typography variant="body1">
                Email: sajan.coldpress@gmail.com
              </Typography>
              <div className={classes.socialMedia}>
                <Typography variant="h6" gutterBottom>
                  Follow Us
                </Typography>
                <Link href="https://www.facebook.com" target="_blank" rel="noopener">
                  Facebook
                </Link>
                {' | '}
                <Link href="https://www.twitter.com" target="_blank" rel="noopener">
                  Twitter
                </Link>
                {' | '}
                <Link href="https://www.instagram.com" target="_blank" rel="noopener">
                  Instagram
                </Link>
                {' | '}
                <Link href="https://www.linkedin.com" target="_blank" rel="noopener">
                  LinkedIn
                </Link>
              </div>
            </Paper>
          </Grid>
        </Grid>
      </Container>
    </Root>
  );
};

export default ContactPage;
