import React, { useState, useRef } from 'react';
import { Box, Button, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

const PREFIX = 'VideoPage';
const classes = {
  root: `${PREFIX}-root`,
  banner: `${PREFIX}-banner`,
  video: `${PREFIX}-video`,
  fallbackImage: `${PREFIX}-fallbackImage`,
  muteButton: `${PREFIX}-muteButton`,
};

const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    position: 'relative',
    width: '100%',
    textAlign: 'center',
    backgroundColor: theme.palette.background.default,
  },
  [`& .${classes.banner}`]: {
    position: 'relative',
    height: '50vh',  // Adjust the height as per your design
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '30vh',
    },
  },
  [`& .${classes.video}`]: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  [`& .${classes.fallbackImage}`]: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    position: 'absolute',
    top: 0,
    left: 0,
  },
  [`& .${classes.muteButton}`]: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    zIndex: 1,
  },
}));

const VideoPage = ({ videourl, fallbackImageUrl }) => {
  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const videoRef = useRef(null);

  const handleVideoLoaded = () => {
    setIsVideoLoaded(true);
  };

  const toggleMute = () => {
    if (videoRef.current) {
      videoRef.current.muted = !isMuted;
      setIsMuted(!isMuted);
    }
  };

  return (
    <Root className={classes.root}>
      <Box className={classes.banner}>
        <video
          ref={videoRef}
          className={classes.video}
          autoPlay
          loop
          muted={isMuted}
          onLoadedData={handleVideoLoaded}
          playsInline
        >
          <source src={videourl} type="video/mp4" />
          <source src={videourl.replace('.mp4', '.webm')} type="video/webm" />
          <source src={videourl.replace('.mp4', '.ogg')} type="video/ogg" />
          Your browser does not support the video tag.
        </video>
        {!isVideoLoaded && <img src={fallbackImageUrl} className={classes.fallbackImage} alt="fallback" />}
        <Button className={classes.muteButton} variant="contained" color="primary" onClick={toggleMute}>
          {isMuted ? "Unmute" : "Mute"}
        </Button>
      </Box>
    </Root>
  );
};

export default VideoPage;
